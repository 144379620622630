import React, {useEffect} from 'react';
import {graphql} from 'gatsby';
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import InnerPage from "../../components/innerPage";
import newsImage from "../../../content/assets/images/news.jpg";
import eventHighlightsImage from "../../../content/assets/images/event-highlights.jpg";

/**
 * media index page
 * @returns {JSX.Element}
 * @private
 */
const MediaIndex = () => {
    const {t} = useTranslation();
    const {languages, originalPath} = useI18next();

    const breadcrumbs = [
        {
            title: t('media'),
        }
    ]

    const mediaPagesList = [
        {
            icon: newsImage,
            text: t('news'),
            url: "/media/news"
        },
        {
            icon: eventHighlightsImage,
            text: t('eventHighlights'),
            url: "/media/event-highlights"
        }
    ]

    useEffect(() => {

    }, [])

    return (
        <Layout>
            <SEO title={t('media')}/>
            <InnerPage
                breadcrumbs={breadcrumbs}
                pageTitle={t('media')}
            >
                <section id="media" className={"tabs py-0"}>
                    <div className="container" data-aos="fade-up" >

                        <div className="row">
                            {mediaPagesList.map((e, index) => {
                                return (
                                    <div className="content col-12 col-md-6 d-flex align-items-stretch my-3 my-md-0"
                                         key={index}
                                    >
                                        <div className="content text-center mx-auto px-0 py-4 hover-highlight">
                                            <Link to={e.url}>
                                                <img src={e.icon} alt={e.text} className={"img-thumbnail"} />
                                                <h3 className={"mt-4"}>{e.text}</h3>
                                            </Link>

                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </section>
            </InnerPage>
        </Layout>
    );
};

export default MediaIndex;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;